$(document).ready(function() {
  const paragraph = document.getElementById('contact-us-paragraph');

  const observer = new IntersectionObserver(paintText, {
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: buildThresholdList()
  });

  if (paragraph) observer.observe(paragraph);
});

function buildThresholdList() {
  let thresholds = [];
  let numSteps = 10;

  for (let i = 1.0; i <= numSteps; i++) {
    let ratio = i/numSteps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
}

function paintText(entries, _) {
  entries.forEach((entry) => {
    const spans = entry.target.querySelectorAll('span');
    let help = spans[0];
    let to = spans[1];
    let entrepreneurship = spans[2];
    let innovation = spans[3];

    help.classList.remove('text-yellow');
    to.classList.remove('text-yellow');
    entrepreneurship.classList.remove('text-yellow');
    innovation.classList.remove('text-yellow');

    if (entry.intersectionRatio >= 0.3) {
      help.classList.add('text-yellow');
    }

    if (entry.intersectionRatio > 0.4) {
      to.classList.add('text-yellow');
    }

    if (entry.intersectionRatio > 0.6) {
      entrepreneurship.classList.add('text-yellow');
    }

    if (entry.intersectionRatio > 0.8) {
      innovation.classList.add('text-yellow');
    }
  });
}
