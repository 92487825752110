$(document).ready(function() {
  const links = document.getElementsByClassName('modal-link');
  const modal = document.getElementById('navbarToggleExternalContent');

  for (i = 0; i < links.length; i++) {
    let link = links[i];
    link.addEventListener('click', (event) => {
      event.preventDefault();
      if ($('.modal.show')) {
        $('.modal-backdrop').remove();
        modal.style.display = 'none';
        document.body.removeAttribute('class');
        document.body.removeAttribute('style');
        window.location.href = link.href;
      }
    });
  }
});
