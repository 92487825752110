$(document).ready(function() {
  $('.link').click(function(e) {
    e.preventDefault();
    $(this).addClass('open-button');
    const href = `${window.location.origin}${$(this).attr('href')}`
    setTimeout( function() {
      window.location.href = href
    }, 500);
  })
});
